class StripePayment extends HTMLElement {
  get stripe() {
    return this._stripe ||= Stripe(this.getAttribute("public-key"))
  };

  async display(rawAmount) {
    let amount = parseFloat(rawAmount);

    if (!amount) {
      this.showError("The amount you entered isn't a valid number. Please try again.");
      this.closest("sl-dialog").open = false;
      setTimeout(() => location.reload(), 3_000);
      return
    };

    let footer = this.querySelector("stripe-payment-footer");
    this.innerHTML = "<form id=\"stripe-payment-form\"><div id=\"stripe-payment-element\" style=\"min-height:220px\"></div></form>";

    if (footer) {
      this.querySelector("form").appendChild(footer);

      footer.querySelector("sl-button").addEventListener("click", () => {
        return this.handleSubmit()
      });

      footer.querySelector("sl-button span").textContent = `$${amount}`
    };

    this.loading = true;
    let apiUrl = this.getAttribute("href");

    let response = await fetch(apiUrl, {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify({amount})
    });

    let data = await response.json();
    let clientSecret = data.clientSecret;

    let appearance = {
      theme: "stripe",
      variables: {colorPrimary: StripePayment.PRIMARY_COLOR}
    };

    this._elements = this.stripe.elements({appearance, clientSecret});
    let paymentElement = this._elements.create("payment");
    paymentElement.mount("#stripe-payment-element");
    return this.loading = false
  };

  async handleSubmit(e=null) {
    if (e) e.preventDefault();
    this.loading = true;

    let paymentResponse = await this.stripe.confirmPayment({
      elements: this._elements,

      confirmParams: {
        return_url: this.getAttribute("return-href"),
        payment_method_data: {billing_details: {email: this.querySelector("stripe-payment-footer sl-input[name='email']").value}}
      }
    });

    this.showError(`Oops! <strong>${paymentResponse.error.message}</strong> If you keep seeing this problem, please email jared@whitefusion.studio`);
    return this.loading = false
  };

  showError(message) {
    let alert = Object.assign(document.createElement("sl-alert"), {
      type: "danger",
      closable: true,
      duration: 6_000,
      innerHTML: `<sl-icon library="remixicon" name="system/alert-fill" slot="icon"></sl-icon>\n${message}\n`
    });

    document.body.append(alert);
    return alert.toast()
  };

  set loading(state) {
    return this.querySelector("stripe-payment-footer sl-button").loading = state
  }
};

StripePayment.PRIMARY_COLOR = "#0284c7";
customElements.define("stripe-payment", StripePayment)